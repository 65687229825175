<div class="wrapper">

	<div class="details">
		<div class="full-name">{{ fullName$ | async }}</div>
		<div class="roles" *ngIf="roles$ | async as roles" [title]="roles">{{ roles }}</div>
	</div>

	<img src="./assets/img/avatar.svg">

</div>
