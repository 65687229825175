<div class="alerts">

	<div
		*ngFor="let alert of alertService.alerts"
		class="item item-{{alert.type}}"
		[class.hiding]="alert.hiding"
	>

		<div class="message">
			{{ alert.message }}
		</div>

		<div class="close-btn" (click)="alertService.hide(alert.id)">
			<fa-icon [icon]="['fas', 'xmark']"></fa-icon>
		</div>

		<div class="p-bar"></div>

	</div>

</div>
