import {Component, Input, OnDestroy} from '@angular/core'
import {NavigationEnd, Router} from '@angular/router'
import {filter, Subscription} from 'rxjs'

import {NavTab} from '@schemes/ui/nav-tab'
import {NavTabsService} from '@services/ui/nav-tabs.service'

@Component({
  selector: 'header-nav-tabs',
  templateUrl: './header-nav-tabs.component.html',
  styleUrls: ['./header-nav-tabs.component.scss'],
})
export class HeaderNavTabsComponent implements OnDestroy {

  @Input() items: NavTab[]

  routerSubs: Subscription
  currUrl = ''

  constructor(
    private router: Router,
    private navTabsService: NavTabsService,
  ) {
    this.routerSubs = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(e => {
        const {urlAfterRedirects} = e as NavigationEnd
        this.currUrl = urlAfterRedirects
      })
  }

  get navTabs(): NavTab[] {
    return this.navTabsService.navTabs
  }

  isSelected(link: string): boolean {
    return this.currUrl.startsWith(link)
  }

  ngOnDestroy() {
    if (this.routerSubs) {
      this.routerSubs.unsubscribe()
    }
  }
}
