import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {AuthGuard} from '@guards/auth.guard'
import {RoleCode} from '@schemes/role'
import {hasRolesForRoot} from '@utils/guard'

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/navigator/navigator.component').then(c => c.NavigatorComponent),
  },
  {
    path: 'klasses',
    loadChildren: () => import('./pages/klasses/klasses.module').then(m => m.KlassesModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'content',
    loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule),
    ...hasRolesForRoot(RoleCode.Admin, RoleCode.Teacher, RoleCode.SubjectLeader),
  },
  {
    path: 'plans',
    loadChildren: () => import('./pages/plans/plans.module').then(m => m.PlansModule),
    ...hasRolesForRoot(RoleCode.Admin, RoleCode.Teacher),
  },
  {
    path: 'subject-groups',
    loadChildren: () => import('./pages/subject-group/subject-group.module').then(m => m.SubjectGroupModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'staff',
    loadChildren: () => import('./pages/staff/staff.module').then(m => m.StaffModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'school',
    loadChildren: () => import('./pages/school/school.module').then(m => m.SchoolModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'notification-campaigns',
    loadChildren: () => import('./pages/notification-campaign/notification-campaign.module').then(m => m.NotificationCampaignModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'my-lessons',
    loadChildren: () => import('./pages/my-lessons/my-lessons.module').then(m => m.MyLessonsModule),
    ...hasRolesForRoot(RoleCode.Teacher),
  },
  {
    path: 'lessons',
    loadChildren: () => import('./pages/lessons/lessons.module').then(r => r.LessonsModule),
    ...hasRolesForRoot(RoleCode.Teacher),
  },
  {
    path: 'my-groups',
    loadChildren: () => import('./pages/my-groups/my-groups.module').then(m => m.MyGroupsModule),
    ...hasRolesForRoot(RoleCode.Teacher),
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'parents',
    loadChildren: () => import('./pages/parents/parents.module').then(m => m.ParentsModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'transfer',
    loadChildren: () => import('./pages/transfer/transfer.module').then(m => m.TransferModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'auth',
    loadComponent: () => import('./pages/auth/auth.component').then(c => c.AuthComponent),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
