import {LOCALE_ID, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {registerLocaleData} from '@angular/common'
import localeRu from '@angular/common/locales/ru'
import moment from 'moment'
import 'moment/locale/ru'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {ModalModule} from 'ngx-bootstrap/modal'
import {HasRolesPipe} from '@pipes/has-roles.pipe'
import {BgImageDirective} from '@directives/bg-image.directive'
import {SafeHtmlPipe} from '@pipes/safe-html.pipe'
import {SidebarComponent} from '@components/sidebar/sidebar.component'
import {HeaderComponent} from '@components/header/header.component'
import {HeaderNavTabsComponent} from '@components/header/nav-tabs/header-nav-tabs.component'
import {HeaderProfileComponent} from '@components/header/profile/header-profile.component'
import {AlertsComponent} from '@components/alerts/alerts.component'
import {DialogComponent} from '@components/dialog/dialog.component'
import {ConfirmModalComponent} from '@components/confirm-modal/confirm-modal.component'
import {ModalComponent} from '@components/modal/modal.component'
import {RequestInterceptor} from '@interceptors/request.interceptor'
import {MasterGuard} from '@guards/master.guard'
import {AuthGuard} from '@guards/auth.guard'
import {RoleGuard} from '@guards/role.guard'

registerLocaleData(localeRu)
moment.locale('ru')

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    HasRolesPipe,
    BgImageDirective,
    SafeHtmlPipe,
    ModalComponent,
  ],
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    HeaderNavTabsComponent,
    HeaderProfileComponent,
    AlertsComponent,
    DialogComponent,
    ConfirmModalComponent,
  ],
  bootstrap: [
    AppComponent,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'ru'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    MasterGuard,
    AuthGuard,
    RoleGuard,
  ],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas)
  }
}
