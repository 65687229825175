import {Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpParameterCodec, HttpParams, HttpRequest} from '@angular/common/http'
import {catchError, Observable, Subject, switchMap, tap, throwError} from 'rxjs'
import moment from 'moment'

import {extractHttpErrorMessage} from '@utils/http'
import {AuthService} from '@services/http/auth.service'
import {AlertService} from '@services/ui/alert.service'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false

  tokenRefreshedSource = new Subject<void>()
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable()

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.addAuthHeader(request)
    request = this.changeParamsEncoding(request)

    if (this.isTokenExpired(request) && !request.url.includes('auth/token/refresh')) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request)
          return next.handle(request)
        }),
        catchError(e => {
          return this.logout(e)
        }),
      )
    }
    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next)
      }),
    )
  }

  changeParamsEncoding(request: HttpRequest<any>) {
    return request.clone({
      params: new HttpParams({
        encoder: new CustomHttpParamEncoder(),
        fromString: request.params.toString(),
      }),
    })
  }

  addAuthHeader(request: HttpRequest<any>) {
    const isJooReq = request.url.includes('joo.kz')
    const isLocalDev = request.url.includes('localhost')
    const accessToken = this.authService.accessToken

    if (isJooReq) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'ru',
        },
      })
    }

    if (isJooReq && accessToken) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${accessToken}`,
        },
      })
    }

    if (isLocalDev) return request.clone({setHeaders: {}}) // for local development custom headers

    return request
  }

  isTokenExpired(request: HttpRequest<any>): boolean {
    if (request.params.has('skip_error_handling')) {
      return false
    }

    if (!this.authService.isAuthenticated) {
      return false
    }

    return moment().diff(this.authService.accessTokenExpireDate, 'seconds') > -30
  }

  handleResponseError(error, request, next) {
    if (request.params.has('skip_error_handling')) {
      return throwError(error)
    }

    if (error.status === 401) {
      if (request.url.includes('auth/token')) {
        return throwError(error)
      }

      return this.refreshToken().pipe(
        switchMap(() => {
          return next.handle(this.addAuthHeader(request))
        }),
        catchError(e => {
          return this.logout(e)
        }),
      )
    }

    let message = ''

    if (error.status === 0) {
      message = request
        ? `Доступ к <code>${request.url}</code> заблокирован политикой CORS. Подробности смотрите в консоли браузера.`
        : `Доступ к HTTP-адресу заблокирован политикой CORS. Подробности смотрите в консоли браузера.`
    } else if (error.status >= 500) {
      message = 'Ошибка сервера'
    } else if (error.status === 404) {
      message = 'Не найдено'
    } else {
      message = extractHttpErrorMessage(error.error)
    }

    this.alertService.show({message, type: 'danger'})
    return throwError(error)
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next()
          observer.complete()
        })
      })
    }

    this.refreshTokenInProgress = true

    return this.authService.refreshTokens().pipe(
      tap(() => {
        this.refreshTokenInProgress = false
        this.tokenRefreshedSource.next()
      }),
      catchError(error => {
        this.refreshTokenInProgress = false
        return throwError(error)
      }),
    )
  }

  logout(e: any): Observable<any> {
    this.alertService.showError('Произошла ошибка при попытке продлить сеанс пользователя.')
    this.authService.logout()
    return throwError(e)
  }
}

class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key)
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value)
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key)
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value)
  }
}
