import {Component} from '@angular/core'

import {environment} from '@env'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor() {
    this.initAnalytics()
  }

  initAnalytics() {
    if (!environment.production) return
    this.injectAmplitude()
    this.injectMicrosoftClarity()
  }

  injectAmplitude() {
    Promise.all([
      new Promise(resolve => {
        const script = document.createElement('script')
        script.src = 'https://cdn.amplitude.com/libs/analytics-browser-2.7.1-min.js.gz'
        script.onload = resolve
        document.head.appendChild(script)
      }),
      new Promise(resolve => {
        const script = document.createElement('script')
        script.src = 'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.2.0-min.js.gz'
        script.onload = resolve
        document.head.appendChild(script)
      }),
      new Promise(resolve => {
        const script = document.createElement('script')
        script.src = 'https://cdn.amplitude.com/libs/plugin-autocapture-browser-0.9.0-min.js.gz'
        script.onload = resolve
        document.head.appendChild(script)
      }),
    ]).then(() => {
      const script = document.createElement('script')
      script.innerHTML = `
          window.amplitude.init('f9d845ca1c69a03330507ce7273d6d46');
          window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1}));
          window.amplitude.add(window.amplitudeAutocapturePlugin.plugin());
        `
      document.head.appendChild(script)
    })
  }

  injectMicrosoftClarity() {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "mcx22jyqe1");
    `
    document.head.appendChild(script)
  }
}
