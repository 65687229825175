<div id="sidebar" [class.visible]="showSidebar | async">

	<ng-container *ngIf="showSidebar | async">

		<div class="details">

			<img class="logo" src="./assets/img/logo.svg">

			<div class="school" *ngIf="school$ | async as school" [title]="school.name">
				<img src="./assets/img/school.svg">
				<div class="school-name">{{ school.name }}</div>
			</div>

		</div>

		<ng-container *ngFor="let item of menuItems">

			<a
				@fadeMenuItem
				class="menu-item"
				*ngIf="(getRoles(item) | hasRoles | async) && (isVisible(item) | async)"
				[class.selected]="isSelected(item)"
				[class.disabled]="item.disabled"
				[routerLink]="getLink(item)"
				[title]="item.name"
			>

				@if (item.icon) {
					<div class="menu-item-icon">
						<i [class]="item.icon"></i>
					</div>
				} @else {
					<div class="menu-item-icon" [innerHTML]="item.svg | safeHtml"></div>
				}

				<div class="menu-item-title">{{ item.name }}</div>

			</a>

		</ng-container>

		<div class="menu-item" title="Выйти" (click)="logout()">
			<div class="menu-item-icon">
				<fa-icon [icon]="['fas', 'arrow-right-from-bracket']" size="lg"></fa-icon>
			</div>
			<div class="menu-item-title">Выйти</div>
		</div>

		<div id="toggle-btn" (click)="toggleSidebar()">
			<fa-icon [icon]="['fas', 'chevron-left']" size="sm"></fa-icon>
		</div>

	</ng-container>

</div>
